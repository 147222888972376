<template>
    <div class="content">
      <img src="@/assets/images/login.svg">
      <form @submit.prevent="login" class="loginCard">
        <input type="checkbox" id="forgotten" class="forgotten">
        <div class="login">
          <div class="loginCard__title">
            <h2>Se connecter</h2>
            <!-- <p>ou <router-link :to="{name: 'register'}">s'inscrire</router-link></p> -->
          </div>
          <div class="loginCard__input">
            <!-- <p class="error">Erreur dans l'adresse mail</p> -->
            <input v-model="username" type="text" placeholder="Adresse mail">
          </div>
          <div class="loginCard__input">
            <input v-model="password" type="password" placeholder="Mot de passe">
          </div>
          <!-- <label for="forgotten">Mot de passe oublié ?</label> -->
          <div class="loginCard__btn">
            <button class="btn btn--blue">Se connecter</button>
          </div>
        </div>
        <div class="forgotten">
          <label for="forgotten" class="back"><i class="fa fa-chevron-left"></i> Retour</label>
          <h2>Vous avez oublié votre mot de passe ? Pas de panique !</h2>
          <p>Entrez l’adresse email de votre compte.<br>Nous vous enverrons un email avec les prochaines étapes.</p>
          <div class="loginCard__input">
            <input type="text" placeholder="">
          </div>
          <button class="btn btn--disabled m-auto">Envoyer</button>
        </div>
      </form>
    </div>
</template>

<script>
import { setCrispInfos } from "@/plugins/crisp";

export default {
  name: 'Login',
  data: () => ({
    username: '',
    password: '',
  }),
  methods: {
    login() {
      const { username, password } = this;
      this.$axios.post(`/api/login_check`, { username, password })
        .then((res) => {
          const date = new Date();
          date.setTime(date.getTime() + 604800 * 1000)
          document.cookie = `JWT=${res.data.token}; expires=${date.toGMTString()}`
          this.$store.dispatch('auth/login');
          // This connects the user in the Crisp chatbox, preventing him from loging in another time inside the app.
          try {
            setCrispInfos(this.$store.getters['auth/userInfo']);
          } catch (e) {
            // Ignore crisp error in dev mode
            console.error(e);
            if (process.env.NODE_ENV === 'production') {
              throw e;
            }
          }
          this.$router.push({ path: '/' })
        })
        .catch(() => {
            this.$notify({
              text: 'Erreur pendant la connexion',
              type: 'error',
            })
        })
    }
  }
};
</script>

<style scoped src="../assets/css/login.css"></style>
<style scoped>

/* Display/hide forgotten form */
.forgotten {
  display: none;
}
#forgotten:checked ~ .login {
  display: none;
}
#forgotten:checked ~ .forgotten {
  display: block;
}

.forgotten p {
  margin-top: 30px;
  font-size: 14px;
}
.forgotten .m-auto {
  display: block;
  margin: auto;
}
</style>

<template>
    <!-- Start login content -->
    <div class="content">
      <img src="@/assets/images/login.svg">
      <form class="loginCard">
        <div class="loginCard__title">
          <h2>S'inscrire</h2>
          <p>ou <router-link :to="{name: 'login'}">se connecter</router-link></p>
        </div>
        <div class="loginCard__input loginCard__input--valide">
          <input type="text" placeholder="Prénom">
        </div>
        <div class="loginCard__input">
          <input type="text" placeholder="Nom">
        </div>
        <div class="loginCard__input">
          <input type="text" placeholder="Email">
        </div>
        <div class="loginCard__input">
          <input type="text" placeholder="Mot de passe">
        </div>
        <div class="loginCard__btn">
          <button class="btn btn--disabled">S'inscrire</button>
        </div>
      </form>
    </div>
    <!-- End login content -->
</template>

<script>
export default {
  name: 'Register',
};
</script>

<style scoped src="../assets/css/login.css"></style>
<style scoped>

.loginCard__input {
  margin-bottom: 20px;
}
</style>
